import React from 'react';
import {graphql, StaticQuery} from 'gatsby';

const EventHeader = () => (
  <StaticQuery query={graphql`
{
  allWordpressPage(filter: {wordpress_id: {eq: 39}}) {
    edges {
      node {
        title
        acf {
          event_header_image {
            source_url
          }
        }
      }
    }
  }
}
  `} render={props => (
<div>
    {props.allWordpressPage.edges.map(page=> (
      <img src={page.node.acf.event_header_image.source_url} class="hero" data-sal="slide-up" data-sal-delay="150" data-sal-easing="ease"/>
    ))}
  </div>  
  )}/>
);
export default EventHeader;