import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EventHeader from '../components/eventheader';
import styled from 'styled-components';
import { navigate } from 'gatsby'


function escapeRegExp(str) {
  return str.replace(/[-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

function clean($input){
  var $find = `href="https://stirtingale.uk/platocms/`;
  var $replace = `href="/`;
  return $input.replace(new RegExp(escapeRegExp($find), 'g'), $replace);
}

function flex($url,$height,$width,$webp){

	var padding = ($height/$width) * 100;

	var flex = `
	<div class='flex-image' style='padding-bottom:`+padding+`%'>
		<picture>
		  <source srcset="`+$webp+`" type="image/webp">
		  <source srcset="`+$url+`" type="image/jpeg"> 
		  <img src="`+$url+`">
		</picture>
	</div>
	`;

	return flex;
}


const redirect = ({ pageContext }) => {
	if(pageContext.enable_standalone_page !== true){
		navigate('/');
	} 
}

function check_redirect_status($status){
	if($status!=="true"){
		navigate('/');
	}
}

function customseo_title($customseo_input,$page_title){
	if( $customseo_input != null ){
		return $customseo_input;
	} else {
		return $page_title;
	}
}

export default ({pageContext}) => (
	<Layout>
		<SEO 
		title={customseo_title(pageContext.acf.custom_title,pageContext.title)}
		description={pageContext.acf.custom_description}
		/>
	    <div id="person-single" class="header-container --simple --eventsingle">
	    <EventHeader/>
	    <div class="colour-mask"></div>
	    <div class="grid-container">
	      <div class="grid-x setheight">
	        <div class="cell small-12 medium-10 medium-offset-1">
	            <div class="header-container-simple ">
	            </div>
	            <div class="inner --simple">
	            <h2 dangerouslySetInnerHTML={{__html:pageContext.title}}/>
	            <ul>
	            <li>
	            <a href="/people"><svg xmlns="http://www.w3.org/2000/svg" fill="var(--white)" width="14" height="14" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg> Back to People</a>
	            </li>
	            </ul>
	            </div>
	        </div>
	      </div>
	    </div>
	    </div>
	    <div id="person-content" class="grid-container">
	      <article class="grid-x">
			<div class='cell small-12 medium-2 medium-offset-1 align-self-top sticky'>
					<div 
						dangerouslySetInnerHTML={{__html:flex(
							pageContext.acf._thumbnail_id.localFile.childImageSharp.fixed.src,
							pageContext.acf._thumbnail_id.media_details.height,
							pageContext.acf._thumbnail_id.media_details.width,
							pageContext.acf._thumbnail_id.localFile.childImageSharp.fixed.srcWebp
						)}} 
					/>
				    <h5><span dangerouslySetInnerHTML={{__html:pageContext.title}}/><br/><small dangerouslySetInnerHTML={{__html:pageContext.position}}/></h5>
 				    <hr/>
				    <p>{pageContext.acf.institutional_affiliation}</p>
				    <p><a href="mailto:{pageContext.acf.email}">{pageContext.acf.email}</a></p>
					<div class='internalnav' dangerouslySetInnerHTML={{__html:pageContext.acf.internal_nav}} />

			</div>
			<div class='content cell small-12 medium-9 large-6 large-offset-1' dangerouslySetInnerHTML={{__html:clean(pageContext.content)}} />
	      </article>
	    </div>
	</Layout>
);